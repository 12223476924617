<template>
  <div>
    <div class="d-flex justify-content-start">

      <h3><strong class="text-primary">RESPUESTAS DEL FORMULARIO:</strong>
        <span class="text-danger">
          " {{ nombreFormulario }} "
        </span>
      </h3>

    </div>
    <RespuestasFormularioIndex
      v-if="idFormulario"
      :id-formulario="idFormulario"
    /></div>
</template>

<script>
import {
} from 'bootstrap-vue'
import RespuestasFormularioIndex from '@/views/administrador/gestion-formulario/nuevo-formulario/respuestas/RespuestasFormularioIndex.vue'

export default {
  components: {
    RespuestasFormularioIndex,
  },
  data() {
    return {
      idFormulario: parseInt(this.$route.params.idFormulario, 10),
      nombreFormulario: '',
    }
  },
  created() {
    this.$http.get(`/recursos/formulario/${this.$route.params.idFormulario}`).then(res => {
      this.nombreFormulario = res.data.nombre
    })
  },

}
</script>

<style>

</style>
